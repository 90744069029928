/* ReportsPage.css */

.reports-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 0;
  background-color: WhiteSmoke !important;
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;
}

.authenticated-content {
  text-align: center;
  max-width: 800px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 3rem;
}

.header-title {
  color: #0047b0;
  margin-bottom: 10px;
}
.header-title-bold {
  color: #0047b0;
  margin-bottom: 10px;
  font-weight: bold;
}

.card-style {
  margin-top: 30px;
}

.plan-dates {
  color: #0047b0;
  font-weight: bold;
}

.toggle-text-button,
.toggle-audio-button {
  padding: 3px;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.toggle-text-button {
  background-color: LimeGreen;
}

.toggle-audio-button {
  margin-top: 8px;
  background-color: #0047b0;
}
