/**
 * Audio controls
 */

.transparent-audio {
  width: 100%;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.header-text-color {
  color: #0047b0;
}
.audio-button {
  background-color: transparent;
  border: none;
  margin-bottom: 10px; 
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.2em;
}

